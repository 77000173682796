@import "../scss/utils";

$mh-modal-with-lg: calc(100vw - 500px);
$mh-modal-with-md: calc(100vw - 300px);
$mh-modal-with-sm: calc(100vw - 10px);
//$mh-modal-with: 800px;
$top-items-margin: 20px;

.modalslide-modal {
  position: fixed;
  top: 25px;
  opacity: 1;

  .modalslide-body {
    height: 100%;
    width: 100%;
    overflow: auto;
  }

  background-color: #444654;
  border: 1px solid rgb(255, 255, 255);
  @include full-border-radius(12px);
  z-index: 1000;

  color: white;
  padding: 10px;
  transition: left 0.25s ease, right 0.25s ease, top 0.25s ease-in-out, bottom 0.25s ease-in-out;

  @media (max-width: 768px) {
    left: calc(50vw - $mh-modal-with-sm / 2);
    right: calc(50vw - $mh-modal-with-sm / 2);
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    left: calc(50vw - $mh-modal-with-md / 2);
    right: calc(50vw - $mh-modal-with-md / 2);
  }

  @media (min-width: 1025px) {
    left: calc(50vw - $mh-modal-with-lg / 2);
    right: calc(50vw - $mh-modal-with-lg / 2);
  }
}

.hidden-right {
  left: 110vw;
  right: -110vw;
  opacity: 0;
}

@keyframes fadeOutAnimation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.hidden-user-nav {
  opacity: 0;
  left: -110vw;
  right: 110vw;
  top: 70vh;
  bottom: -70vh;
}

.modalslide-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 500;
  transition: background-color 0.2s ease;
}

.modalslide-backdrop-transparent {
  background-color: rgba(0, 0, 0, 0);
}

.modalslide-top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.modalslide-close, .modalslide-close:hover, .modalslide-close:focus {
  background: none!important;
  border: none;
  margin: $top-items-margin 5px 0 0;
}

.modalslide-title {
  font-weight: bold;
  margin: $top-items-margin 0;
  font-size: 20px;
}

.modalslide-description {
  font-style: italic;
}

.modalslide-form {
  display: flex;
  margin: 10px 0 0 0;
  padding: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
}

.modalslide-text {
  max-height: 50vh;

  @media (max-height: 590px) {
    max-height: 30vh;
  }
}

.modalslide-form-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;

  input, textarea, select {
    font-size: 1rem;
    line-height: 1.5;
    color: white;
    background-color: #4c4f5f;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }

  textarea {
    resize: none;
  }

  select {
    height: 2rem;
  }

  .modalslide-form-row {
    display: flex;
    flex-direction: row;

    input, textarea, select {
      display: block;
      flex: 1;
    }
  }
}

.modalslide-form-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  button {
    @include full-border-radius(0);

    width: auto;
    background: #292932;
    color: rgb(255, 255, 255);
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.5rem;
    align-items: center;
    font-size: 1.1rem;
    line-height: 2.5rem;
    height: auto;

    span {
      display: block;

      svg {
        display: block;
      }
    }


    &:hover {
      @include full-border-radius(0);
    }
    &:first-child {
      @include full-border-left-radius(5px);
      &:hover {
        @include full-border-left-radius(5px);
      }
    }
    &:last-child {
      @include full-border-right-radius(5px);
      &:hover {
        @include full-border-right-radius(5px);
      }
    }
  }

}

