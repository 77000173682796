@import "../scss/utils";

.mhp-url-row {
  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.mhp-article-summariser {
  bottom: 25px;

  .modalslide-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .mhp-add-links-form-wrapper {
      form {
        flex: 1;
        .modalslide-form-group {
          flex: 1;
          .modalslide-form-row {
            flex: 1;
          }
        }
      }
    }
  }
}

.mhp-article-getter {
  padding: 1rem;

  input, textarea, button {
    &[disabled]:hover {
      cursor: progress;
    }
  }
}

.mhp-add-links-form-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}


.mhp-get-articles-wrapper {
  //padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1rem 0;

  .mhp-get-article-row {
    background: #393b46;
    //padding: 1rem;
  }
}

.mhp-summarise-articles-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  button {
    border: none;
    border-radius: 0;
    background: #292932;
    color: rgb(255, 255, 255);
    padding: 10px 20px;
    display: block;

    &:hover {
      border-radius: 0;
    }

    &[disabled] {
      background: rgba(255, 255, 255, 0.4);
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}

.mhp-get-articles-list-head {
  display: flex;
  flex-direction: row;

  .mhp-get-articles-loader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 1rem;
  }

  &:hover {
    cursor: pointer;
    .mhp-remove-article {
      width: 3rem;
    }
  }

  .mhp-remove-article {
    width: 0;
    transition: width 0.1s ease-in-out;
    overflow: hidden;
    button {
      border: none;
      background: rgba(255, 255, 255, 0.1);
      color: rgba(255, 255, 255, 0.8);
      padding: 1rem;

      &:hover {
        border-radius: 0;
        background: rgba(173, 105, 105, 0.2);
        color: rgba(255, 255, 255, 1);
      }
    }
  }

  .mhp-get-articles {
    width: 4rem;
  }

  .mhp-get-articles-list-head-domain {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 1rem;
  }

  .expandable {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1rem;
    svg {
      display: block;
      rotate: -90deg;
      transition: rotate 0.3s ease-in;
    }
    &.down {
      svg {
        rotate: -180deg;
      }
    }
  }
}

.mhp-get-articles-list-wrapper {
  padding: 0;
  height: 0;
  overflow: hidden;
  transition: height 0.5s ease-in-out;

  &.expanded {
    height: 35rem;
  }
}

.mhp-url-field {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 14px;
  display: block;
}

.mhp-urls-field {
  padding: 0.5rem 0 0.5rem 0.2rem;
}

.mhp-gettext-btn.is-disabled {
  border-top: 1px solid #78797c;
  border-right: 1px solid #78797c;
  border-bottom: 1px solid #78797c;
  background: rgb(77, 77, 93);
  &:hover {
    background: rgb(77, 77, 93);
    cursor: not-allowed;
  }
}
.mhp-gettext-btn {
  min-width: 10rem;
  border-top: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  color: rgba(255, 255, 255, 1);
  @include full-border-left-radius(0);
  @include full-border-right-radius(0.25rem);
  &:hover {
    color: rgba(255, 255, 255, 0.9);
    @include full-border-left-radius(0);
    @include full-border-right-radius(0.25rem);
  }
}

.article-text-block {
  position: relative;
}

.article-loaded-text {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #444654;
  display: flex;
  flex-direction: column;
  //align-items: stretch;
  //justify-content: stretch;

  &.centered {
    justify-content: center;
  }

  .article-loaded-text-status {
    //flex: 1;
    text-align: center;
    font-style: italic;
    color: rgba(255, 255, 255, 0.8);
  }

  .article-text-preview {
    height: auto;
    overflow: auto;
    &.is-closed {
      height: 0;
    }
  }
}
