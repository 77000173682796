.sync-info {
  position: fixed;
  top: 0.1rem;
  right: 0.1rem;
  bottom: 0.1rem;
  left: 0.1rem;
  z-index: 9999;
  background: rgba(20, 20, 22, 0.95);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  gap: 2rem;

  &.cursor:hover {
    cursor: pointer;
  }

  &.shown {
    opacity: 1;

    .sync-info-message {
      margin-bottom: 0;
      opacity: 1;
    }
  }

  .sync-info-message {
    display: flex;
    flex-direction: row;
    line-height: 1.5rem;
    gap: 1rem;
    margin-bottom: -20rem;
    transition: opacity 0.2s ease-in-out, margin-bottom 0.2s ease-in-out;
    opacity: 0;
  }

  .sync-info-loading {
    border-top: .2rem solid #fff;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .sync-result {
    display: flex;
    transition: opacity 0.15s ease-in-out, margin-bottom 0.15s ease-in-out;
    opacity: 0;
    margin-bottom: -40rem;

    &.show-result {
      opacity: 1;
      margin-bottom: 0;
    }

    .sync-result-info {
      display: flex;
      flex-direction: row;
      line-height: 40px;
      gap: 1rem;
    }
  }
}
