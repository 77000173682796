@import './bootstrap.custom';

:root {
  --text-font: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  --primary-bg-color: #282c34;
  --secondary-bg-color: #575969;
}

html {
  background: #202022;
}

.App {
  height: 100vh;
  overflow: hidden;
  font-family: var(--text-font);
  text-align: center;
  background-color: var(--primary-bg-color);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}
a {
  color: white;
}
header {
  display: none;
}

main {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
@media (min-width: 1025px) {
  main {
    height: 100vh;
  }
}
header h1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #d9d9e3;
  margin: auto;
}
.menu button {
  background: none;
  border: none;
}
nav {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  height: 100vh;
}

.disabled {
  pointer-events: none;
}

.navItems {
  padding: 8px;
  background-color: #212122;
  width: 100%;
  max-width: 340px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.navItems-chats {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding-bottom: 20px;
  flex: 1;
}
.navLinks {
  /*flex: 1;*/
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
  /*flex: 1;*/
  padding: 10px 0 20px;
}
.navItems-prompts {
  /*max-height: 40vh;*/
}
.navPromptWrapper {
  overflow-y: auto;
  overflow-x: hidden;
}
.navPromptWrapper.chat-logs {
  max-height: calc(100vh - 245px);
}

.modalBar {
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  max-height: 100vh;
  overflow: auto;
}

.modalBarBlock:last-child {
  //margin-bottom: 2em;
}

.modalBarBlockTitle {
  //border: 1px solid hsl(0deg 0% 100% / 20%);
  margin: 0.5rem 10px;
  border-bottom: 1px solid hsl(0deg 0% 100% / 20%);
  padding: 10px;
  text-align: left;
  font-size: 14px;
  transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;

  &:hover {
    cursor: default;
  }
}

.navPromptWrapper.prompt-list {
  max-height: calc(100vh - 50px);
}

.navPrompt-wrapper {
  position: relative;
}

.navPrompt {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: stretch;
  padding: 10px;
  margin: 5px 0;
  text-align: left;
  color: white;

  flex-direction: row;
}
.navPrompt.selected {
  background-color: rgba(255, 255, 255, 0.1);
}

.navPrompt .nav-link-body {
  display: flex;
  align-items: center;
  /* column-gap: 10px; */
  text-decoration: none;
  flex: 1;
  flex-direction: row;
}
.navPrompt p {
  font-size: 14px;
  line-height: 20px;
  color: #ececf1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  padding-left: 10px;
  flex: 1;
}

.navPrompt.selected p {

  @media (max-width: 1024px) {
    max-width: 15rem;
  }

  @media (min-width: 1025px) {
    max-width: 150px;
  }
}

.navPrompt input {
  background-color: transparent;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
  margin: 0;
  padding-left: 10px;
  border: none;
}
.navClearConversations-wrapper {
  position: relative;
}
.navLinkExpandable {
  margin: 0;
  padding: 0;
}
.navPromptChildren-wrapper {
  position: relative;
  overflow: visible;
  height: 0;
}
.navPromptChildren {
  position: absolute;
  /* Here we have positioning depending of NavLink height + padding + margins */
  bottom: calc(40px + 10px);
  width: 100%;
  max-height: 0;
  overflow: hidden;
  background-color: #3c3c41;
  opacity: 0;
  transition:
    opacity 0.1s linear,
    max-height 0.2s linear;
  -webkit-transition:
    opacity 0.1s linear,
    max-height 0.2s linear;
  -moz-transition:
    opacity 0.1s linear,
    max-height 0.2s linear;

  border-radius: 5px;
}

.navPromptChildren-delimeter {
  background-color: rgba(255, 255, 255, 0.2);
  height: 1px;
  margin: 0 8px;
}

.navPromptChildren-wrapper.visible .navPromptChildren {
  opacity: 1;
  max-height: 500px;
}
.navConfirmClearChats {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  height: 140px;
  font-size: 0.9rem;
  padding: 1rem;
}

.navConfirmClearChats-question {
}

.navConfirmClearChats-downloadForm {
  margin: 0;
  padding: 2rem 0 0;
  font-size: 0.8rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.navConfirmClearChats-downloadForm label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.navConfirmClearChats-downloadForm label:hover {
  cursor: pointer;
}
.navConfirmClearChats-downloadForm label input[type='checkbox'] {
  margin-right: 0.2rem;
  display: block;
}
.navConfirmClearChats-downloadForm label span {
  display: block;
}

.company-logo-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  padding-left: 10px;
  margin-bottom: 10px;
}
.company-logo-wrapper img {
  display: block;
}

.clearActions {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.clearActions button {
  position: inherit;
  background: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.iconContainer {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* width: 60px;  */
  height: 18px;
  overflow: hidden;
  cursor: pointer;
}
.iconClick {
  opacity: 0.5;
  transition: opacity 0.2s ease;
}

.iconClick:hover {
  opacity: 1;
  background: none;
}

.iconClick:active {
  opacity: 1;
}

.toolBox {
  display: flex;
  align-items: center;
  margin: 0 0 0 auto;
}
.delete-confirm-box-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  transition: width 0.2s ease-in-out, right 0.2s ease-in-out;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  &.toolBox> * {
    padding: 0;
  }

  .delete-confirm-box {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: stretch;
    gap: 0.5rem;
  }

  &.opened {
    width: 40px;
    right: 10px;
  }
}
.toolBox > * {
  padding: 0 5px;
}
.navPrompt span {
  animation: fadeInChar 1s ease-in forwards;
  opacity: 0;
}
.botMessage {
  flex: 1;
}
.botMessage pre {
  padding-left: 24px;
  padding-top: 25px;
  overflow-x: auto;
  max-width: 800px;
  /* white-space: pre-wrap; */
  margin: 0;
  /* padding: 0; */
  text-indent: 0;

  position: relative;
  text-align: justify;
  top: -20px;
  margin-bottom: 10px;
  left: -24px;
}
/* Markdown table styling */

.markdown-table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 1em;
}
.markdown-table th,
.markdown-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

pre {
  font-family: var(--text-font);
}
@keyframes fadeInChar {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.navCloseIcon {
  flex: 1;
  margin: 10px;
}
.navCloseIcon:hover {
  cursor: pointer;
}
nav svg {
  float: left;
}

.sideMenu {
  padding: 8px;
  background-color: #202022;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  max-width: 244px;
  min-width: 100px;
  width: calc(100% - 915px);
}

.expandableSideMenu {
  height: 100vh;
  overflow: auto;
}

@media (max-width: 1024px) {
  .sideMenu.expandableSideMenu {
    position: fixed;
    z-index: 499;
    top: 0;
    right: -260px;
    width: 244px;
    height: 100vh;
    display: flex !important;
    transition: right 0.25s ease;
    overflow: visible;
  }

  .sideMenu.expandableSideMenu.opened {
    right: 0;
  }

  .sideMenu.expandableSideMenu.opened .sideMenuToggle {
    right: 252px;
    width: 40px;
    padding-right: 0;
    padding-left: 10px;
    border-top-right-radius: 0;
    -webkit-border-top-right-radius: 0;
    -moz-border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    -webkit-border-bottom-right-radius: 0;
    -moz-border-bottom-right-radius: 0;
  }
}

.sideMenuOpenWrapper {
  position: relative;
}

@media (min-width: 1025px) {
  .sideMenuOpenWrapper {
    display: none;
  }
}

.sideMenuToggle {
  position: absolute;
  right: 248px;
  top: 30px;
  background: #202022;
  font-size: 14px;
  color: #ffffff;
  padding: 0.8rem 0.8rem 0.8rem 0.6rem;
  outline: none;
  border: none;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  width: 40px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.3rem;
}
.sideMenuToggle:hover {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  background: hsla(240, 7%, 21%, 1);
}

@media (max-width: 1024px) {
  .sideMenuToggle .sideMenuToggleContentOpened {
    display: none;
  }

  .sideMenuToggle .sideMenuToggleContentClosed {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
  }

  .sideMenuToggle .sideMenuToggleContentClosed span {
    display: block;
  }

  .sideMenuToggle span {
    display: block;
  }

  .sideMenuToggle svg {
    display: block;
  }

  .sideMenuToggle.opened .sideMenuToggleContentOpened {
    display: flex;
  }

  .sideMenuToggle.opened .sideMenuToggleContentClosed {
    display: none;
  }
}

.sideMenu.fixed-width {
  width: 244px;
}

.sideMenuButton {
  border: 1px solid hsl(0deg 0% 100% / 20%);
  border-radius: 5px;
  padding: 10px;
  text-align: left;
  font-size: 14px;
  transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;

  &.has-new-chat:hover {
    background-color: hsla(240, 9%, 29%, 0.1);
    cursor: default;
  }
}
.form-group {
  margin-bottom: 1rem;
}
.form-group::label {
  padding-bottom: 5px;
  padding-right: 100px;
}

.form-group textarea::placeholder {
  color: rgb(153, 151, 151);
}
.form-control {
  display: block;
  width: 99%;
  font-size: 1rem;
  line-height: 1.5;
  color: white;
  background-color: #4c4f5f;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.modal {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
  /* background-color: #4c4f5f; */
  background-color: #444654;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  position: absolute;
  width: 450px;
  /* height: 250px; */
  top: calc(30% - 125px);
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center horizontally */
  bottom: calc(30% - 125px);
  z-index: 1000;
  padding: 10px;
  color: white;
}
.form-control::placeholder {
  color: white;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 500;
}
.info-input {
  display: block; /* Make the div a block-level element */
  margin-bottom: 10px; /* Add some space between each block element */
}
.navPrompt:hover,
.sideMenuButton:hover {
  cursor: pointer;
  background-color: hsla(240, 9%, 59%, 0.1);
}
.sideMenuButton span {
  font-size: 20px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 10px;
}
.chatBox {
  flex: 1;
  background-color: #353540;
  line-height: 24px;
  color: #d1d5db;
  font-size: 16px;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 1025px) and (max-width: 1419px) {
  .chatBox {
    max-width: 900px;
  }
}
#introsection::before,
#introsection::after {
  content: '';
  float: left;
  color: rgb(0, 134, 244);
}
#introsection {
  text-align: left;
  padding: 20px;
}
#introsection h1 {
  padding-top: 20px;
  line-height: 33px;
  text-align: center;
  max-width: 800px; /* Set a max-width to maintain readability */
  margin: 0 auto; /* Horizontally center the element */
}

#introsection h2 {
  padding-top: 20px;
  line-height: 33px;
  text-align: left;
  max-width: 800px; /* Set a max-width to maintain readability */
  margin: 0 auto; /* Horizontally center the element */
}

#introsection h3 {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  max-width: 800px; /* Set a max-width to maintain readability */
  margin: 0 auto; /* Horizontally center the element */
}

#introsection .centered-content {
  max-width: 800px;
  margin: 0 auto; /* Horizontally center the element */
}

#introsection ul {
  list-style-type: square;
}
#introsection pre {
  margin: 0;
  display: inline;
}
.chatLogWrapper {
  max-height: calc(100vh - 2 * 40px - 32px - (60px + 15px));
  overflow-y: auto;
  flex: 1;
}

/*@media (max-height:740px) {*/
@media (max-width: 1024px) {
  .chatLogWrapper {
    max-height: calc(100vh - 35px - 30px - 48px - 30px - 26px - 50px);
  }
}

.chatLog {
  &.scrolling {
    opacity: 0.3;
  }
}



/* adding custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
  background-color: #575969;
}
::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 2px;
  background-color: rgb(243, 241, 241);
}

::-moz-scrollbar {
  width: 8px;
  background-color: #575969;
}
::-moz-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 2px;
  background-color: rgb(243, 241, 241);
}

.chatPrompt {
}
.chatPromptPadding {
  padding: 24px;
}
.chatPromptWrapper {
  margin: auto;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  flex: 1;

  &>div:first-child, &>div:last-child {
    @media (min-width: 1400px) {
      flex: 1;
    }
  }
  &>div:first-child {
    @media (min-width: 1400px) {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
  .balance {
    @media (min-width: 1400px) {
    }
  }
  .chatPrompt {
    @media (min-width: 1400px) {
      max-width: 45rem;
      width: 45rem;
    }
  }

  .chatlog-avatar {
    align-items: flex-end;
  }
  .chatPrompt {
    display: flex;
  }
}

.userSVG {
  transform: scale(0.6);
}

.bot-response {
  display: flex;
  flex-direction: row;
  justify-content: stretch;

  .text-element-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media (min-width: 1400px) {
      width: 45rem;
    }
  }

  &>div {
  }

  &>div:first-child, &>div:last-child {
    @media (min-width: 1400px) {
      flex: 1;
    }
  }

  &>div:first-child {
    display: flex;
    flex-direction: row;
    @media (min-width: 1400px) {
      justify-content: flex-end;
    }
  }
}

.botMessageMainContainer {
  width: 100%;
  background-color: #444654;
  position: relative;
}
.botMessageWrapper {
  margin: auto;
  padding: 24px;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  column-gap: 25px;
  min-height: 10px;
}
.text-element {
  padding-left: 24px;
  flex: 1;
  margin-top: 0;

  @media (min-width: 1025px) {
    max-width: 29.5rem;
  }
  @media (min-width: 1240px) {
    max-width: 45rem;
  }
}
.markdown-container {
  overflow-x: auto !important;
}

.inline-code {
  background-color: #000000;
  padding: 2px 4px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  font-family: monospace;
}

.block-code {
  display: block;
  background-color: #000000;
  padding: 10px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  font-family: monospace;
  white-space: pre-line;
}

.stop-message {
  z-index: 498;
  position: absolute !important;
  bottom: 10px;
  right: 50px;
  min-width: 100px; /* Set a minimum width */
  max-width: 200px; /* Set a maximum width */
  width: auto; /* Allow the width to adjust based on the content */
  height: 35px; /* Set a fixed height */
  padding: 8px 12px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border: 1px solid rgb(86, 88, 105);
  background-color: rgb(52, 53, 65);
  color: rgb(217, 217, 217);
  cursor: pointer;
  font-size: 15px;
  box-sizing: border-box; /* Include padding and border in the total width and height */
  text-align: center; /* Center the text within the button */
  white-space: nowrap; /* Ensure the button text stays on a single line */
}
.stop-button {
  z-index: 1000;
}
.stop-messgage:hover {
  background-color: rgb(64, 65, 79);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}
.errorMessage {
  color: #ef4444;
  font-size: 16px;
  line-height: 24px;
}

.openaiSVG {
  transform: scale(0.5);
  svg {
    //padding-top: 10px;
  }
}
.openaiSVG, .userSVG {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    display: block;
  }
}
.chatlog-avatar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  object-fit: contain;
  object-position: center;
}
.bot-response-tools {
  flex: 1;
  width: 36px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.clipboard {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 15px;
  height: 36px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  object-fit: contain;
  object-position: center;
  overflow: hidden;

  .clipboard-icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    transition: margin-left 0.1s ease-in  ;
  }

  &.clipboard-copied {
    .clipboard-icons {
      margin-left: -15px;
    }
  }

  svg {
    display: block;
  }
}

//.progress-loader::before {
//  content: "";
//  box-sizing: border-box;
//  position: absolute;
//  inset: 0px;
//  border-radius: 50%;
//  border: 5px solid #FFF;
//  animation: prixClipFix 2s linear infinite ;
//}

.progress-loader {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .progress-numbers {
    position: absolute;
    font-size: 0.6rem;
  }

  .circular-progress {
    display: block;
    --size: 24px;
    --half-size: calc(var(--size) / 2);
    --stroke-width: 2px;
    --radius: calc((var(--size) - var(--stroke-width)) / 2);
    --circumference: calc(var(--radius) * pi * 2);
    animation: progress-rotation 2s linear infinite;
  }

  .circular-progress circle {
    cx: var(--half-size);
    cy: var(--half-size);
    r: var(--radius);
    stroke-width: var(--stroke-width);
    fill: none;
    stroke-linecap: round;
  }

  .circular-progress circle.bg {
    stroke: #454556;
  }

  .circular-progress circle.fg {
    transform: rotate(-90deg);
    transform-origin: var(--half-size) var(--half-size);
    stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
    transition: stroke-dasharray 0.3s linear 0s;
    stroke: #9ea4a9;
  }

  @keyframes progress-rotation {
    0% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(270deg);
    }
    100% {
      transform: rotate(450deg);
    }
  }
}

.chat-files {
  position: absolute;
  z-index: 499;
  bottom: 45px;
  background-color: #4e4e5e;
  right: 0;
  left: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  transition: max-height 0.1s ease-in-out;

  &.hidden {
    max-height: 0;
    overflow: hidden;
  }

  .chat-files-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    padding: 0 1rem;

    .loader-wrapper {
      padding: 0 .5rem;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: stretch;
    }

    .chat-files-bar-name {
      flex: 1;
      text-align: left;
      line-height: 45px;
      height: 45px;
      opacity: 0.8;
      text-wrap: avoid;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 0.95rem;
      word-break: break-all;
      &:hover {
        cursor: default;
      }
    }

    .chat-files-bar-indicator {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .indicator {
        transition: rotate 0.1s ease-in-out;
        rotate: 0;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .chat-files-bar-removing {
      width: 0;
      opacity: 0;
      overflow: hidden;
      transition: width 0.1s ease-in-out;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      button {
        width: auto;
        display: flex;
        border-radius: 0;
        color: rgba(255, 255, 255, 0.8);
        padding: 0 1rem;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 0.2rem;

        span {
          display: block;
        }

        .remove-label {
          font-weight: bold;
        }
      }

      &.shown {
        width: 8rem;
        opacity: 1;
      }
    }
  }

  .chat-files-list {
    overflow: hidden;
    transition: height 0.1s ease-in-out;
    .uploaded-file {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      line-height: 40px;

      &.disabled-as-legacy {
        opacity: 0.5;
      }

      .file-upload-progress {
        //padding: 0 0 0 1rem;
        padding: 0;
        width: 3rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      .uploaded-file-name {
        flex: 1;
        text-align: left;
        padding: 0 1rem;
        line-height: 40px;
      }

      .uploading-file-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 40px;

        .uploaded-file-name {
          font-size: 1rem;
          line-height: 35px;
          padding: 0 1rem 0 0;
        }

        .uploading-file-info__status {
          font-size: 0.8rem;
          line-height: 5px;
          text-align: left;
          color: rgba(209, 213, 219, 0.8);
          padding: 0 1rem 0 0;
        }
      }

      @keyframes progress-opacity {
        0% {
          opacity: 0.7;
        }

        50% {
          opacity: 0.2;
        }

        100% {
          opacity: 0.7;
        }
      }

      .uploaded-file-check {
        position: relative;
        width: 2rem;
        padding: 0 0.5rem;

        &~ .uploaded-file-name {
          padding-left: 0;
        }

        .unchecked, .checked {
          position: absolute;
          width: calc(100% - 1rem);
          height: 100%;
          transition: opacity 0.1s ease-in-out;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          svg {
            display: block;
          }
        }

        .checked {
          opacity: 0;
        }
        .unchecked {
          opacity: 0.7;
        }
        &.clickable:hover {
          cursor: pointer;
          .checked {
            opacity: 0;
          }
          .unchecked {
            opacity: 1;
          }
        }
        &.changing {
          .unchecked {
            animation: progress-opacity 1s linear infinite;
          }
        }
      }

      &.selected {
        .uploaded-file-check {
          .checked {
            opacity: 0.7;
          }
          .unchecked {
            opacity: 0;
          }

          &:hover {
            .checked {
              opacity: 1;
            }
            .unchecked {
              opacity: 0;
            }
          }


          &.changing {
            .checked {
              animation: progress-opacity 1s linear infinite;
            }
            .unchecked {
              animation: none;
            }
          }
        }
      }
    }

    .unavailable-file-list {
      &:hover {
        cursor: not-allowed;
      }
      .uploaded-file-name {
        color: rgba(150, 150, 150, 1);
      }
    }

    .legacy-files-message {
      display: none;
      opacity: 0;
      position: fixed;
      font-size: 0.8rem;
      padding: 0.5rem;

      transition: opacity .3s ease-in;

      p {
        margin: 0;
      }

      &.displayed {
        display: flex;
        flex-direction: column;
        background: #3c3c41;
      }
      &.shown {
        opacity: 1;
      }
    }
  }

  &.closed {
    &:hover {
      background-color: #65657a;
    }
    .chat-files-bar:hover {
      cursor: pointer;
    }
    .chat-files-list {
      height: 0;
    }
    .chat-files-bar-indicator {
      display: flex;
      justify-content: center;
      align-items: center;

      .indicator {
        rotate: -90deg;
      }
    }
  }
}

.uploaded-file span {
  margin-left: 5px;
}

.autocomplete-suggestions {
  position: absolute;
  bottom: 45px;
  width: 100%;
  overflow-y: auto;
  max-height: 17rem;
  place-items: end;
  background-color: #41414e;
  z-index: 505;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

}
.suggestion-item {
  display: flex;
  align-items: flex-end; /* Vertically align items in the suggestion item */
  padding: 2px; /* Add padding to the suggestion items as needed */
  border-bottom: 1px solid #555; /* Add a border or separator between items */
  line-height: 2rem;
}

/* Style for the last suggestion item, if necessary */
.suggestion-item:last-child {
  border-bottom: none; /* Remove the border for the last item */
}
.mainInputTools {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  padding: 40px;
  position: relative;
}
.file-dropzone-label {
  color: #fff;
}
.file-drag-enter {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  .file-dropzone {
    display: none;
  }
  &.file-hover {
    .file-dropzone {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 501;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(45, 51, 58, 0.85);
    }
  }
}
.mainInputWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  position: relative;
}
.mainInputWrapper form {
  min-width: 80%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.selected-suggestion {
  background: #555564;
  cursor: pointer;
}

.inputPromptWrapper {
  width: 100%;
  max-height: 200px; /* Set maximum height */
  background-color: #41414e;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  align-items: flex-start; /* Anchor items to the top */
  position: relative;
}

.inputPromptWrapper > button {
  position: absolute;
  bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.inputPromptWrapper > button > svg {
  display: block;
}

@media (max-width: 1024px) {
  .inputPromptWrapper {
  }
}

.inputPromptTextarea {
  padding-right: 50px;
  padding-left: 50px;
  padding-top: 15px;
  flex: 1;
  resize: none;
  background-color: #41414e;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  overflow-y: auto;
  white-space: pre-wrap;
  word-break: break-word;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  max-height: 75px;
}
.inputPromptTextarea:first-child {
  padding-left: 1rem;
}

.promptButton {
  background: #292932;
  color: rgb(255, 255, 255);
  height: 30px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}
.promptButton:hover {
  background: #545463;
  color: rgb(255, 255, 255);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}
form button {
  background: rgb(65, 65, 78);
  border: none;
  right: 15px;
  width: 35px;
  height: 35px;
  border-radius: 20%;
  -webkit-border-radius: 20%;
  -moz-border-radius: 20%;
}

/* fileUploadButton */

.fileUploadButton {
  background: rgb(65, 65, 78);
  border: none;
  left: 8px;
  /*top: 20%; */
  width: 35px;
  height: 35px;
  border-radius: 20%;
  -webkit-border-radius: 20%;
  -moz-border-radius: 20%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fileUploadButton svg {
  fill: #adacbf;
  width: 22px;
  height: 22px;
}

.fileUploadButton:hover {
  border-radius: 20%;
  -webkit-border-radius: 20%;
  -moz-border-radius: 20%;
}

.uploading-indicator {
  position: fixed;
  bottom: 10%; /* Position at the bottom of the screen */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust for centering */
  background-color: rgba(47, 42, 42, 0.8); /* Semi-transparent black background */
  color: white; /* White text */
  padding: 6px 20px; /* Padding around the text */
  border-radius: 3px; /* Rounded corners */
  font-size: 16px; /* Text size */
  box-shadow: 0px 2px 4px rgba(47, 42, 42, 0.5); /* Subtle shadow for depth */
  z-index: 1000; /* Ensure it's above other elements */
  transition: all 0.3s ease-in-out; /* Smooth transition for showing and hiding */
}

.upload-success-message {
  position: fixed;
  bottom: 10%; /* Adjust as needed */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust for centering */
  background-color: rgba(47, 42, 42, 0.8);
  color: white;
  padding: 6px 20px;
  border-radius: 3px;
  font-size: 16px;
  box-shadow: 0px 2px 4px rgba(47, 42, 42, 0.5);
  z-index: 1000;
  transition: all 0.3s ease-in-out;
  animation: fadeOut 3s ease-in-out forwards;
  animation-delay: 2s; /* Delay */
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}

/* End fileUploadButton */

@media (hover: hover) {
  button:not(.btn):hover {
    cursor: pointer;
    background: #212023;
    border-radius: 20%;
  }
}

.loginContainer {
  background: var(--primary-bg-color);
  width: 100%;
  height: 100%;
  font-family: var(--text-font);
}

.loginContainerContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.loginContainerContent svg {
  padding-bottom: 30px;
}

.loginContainer h1 {
  font-size: 30px;
  margin: 0;
}

.loginContainer p {
  font-size: 18px;
  line-height: 5px;
}

.loginButtonWrapper {
  display: flex;
  align-items: center;
  column-gap: 15px;
  margin-top: 20px;
}

#loginButton {
  padding: 10px 25px;
  border-radius: 7px;
  color: #fff;
  background: #10a37f;
  border: none;
  font-size: 16px;
  cursor: pointer;
}
#loginButton:hover {
  background-color: rgb(26, 127, 100);
}

@media screen and (min-width: 768px) {
  .inputPromptWrapper {
  }
}

@media (max-width: 1024px) {
  header {
    display: flex;
    align-items: center;
    background: #353441;
    border-bottom: 1px solid hsl(0deg 0% 100% / 20%);
    padding: 4px 0 0 12px;
  }
  nav {
    display: flex;
    transition: left 0.25s ease, background 0.25s ease-in;
    left: -26rem;

    &.opened {
      left: 0;
      background: #1b1c1e;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.37) 45%, rgba(0,0,0,.5) 100%);
    }
  }
  .sideMenu {
    display: none;
  }
  .chatBox {
    position: static;
  }
  /* .chatPromptWrapper {
    padding: 12px;
  }
  .botMessageWrapper {
    padding: 12px;
  } */

  .stop-messgage {
    right: 5px;
    font-size: 13px;
    padding: 8px 15px;
  }
  .userSVG {
    transform: scale(0.5);
  }
  .openaiSVG {
    transform: scale(0.4);
  }
  .chatlog-avatar {
    width: 30px;
    height: 30px;
  }
  #introsection {
    padding: 20px 20px 40px 20px;
    font-size: 14px;
  }
  #introsection h1,
  pre {
    font-size: 16px;
    white-space: pre-wrap;
  }
  #introsection h2 {
    font-size: 14px;
  }
}

@media screen and (min-width: 1536px) {
  .loginContainer h1 {
    font-size: 40px;
  }
  .loginContainer p {
    font-size: 25px;
  }
  #loginButton {
    font-size: 22px;
  }
}

.search-container {
  padding: 5px 0;
  position: relative;
  height: 40px;
}

.search-container input {
  width: 100%;
  padding: 5px 30px 5px 10px;
  box-sizing: border-box;
  font-size: 14px;
}

.search-container .clean-search {
  position: absolute;
  right: 10px;
  top: 15px;
}

.d-none {
  display: none;
}


.llm-switcher {
  display: flex;
  padding: 10px 0;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  min-height: 32px;

  .llm-label {
    font-size: 14px;
    font-weight: 500;
  }

  .dropdown-item {
    width: auto;
  }
}

.loadable {

}

.page {
  flex: 1;
}

.chats-page {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
}

.chat-uploads-modal {
  .modalslide-title {
    padding-left: 1.8rem;
    padding-bottom: 1rem;
  }
  .chats-uploads {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0 1rem;
    //min-height: 5rem;


    .loading-wrapper {
      width: 3rem;
    }

    .chats-uploads-row {
      &:first-child {
        margin-top: 1rem;
      }

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      height: 4rem;

      &.inactive {
        opacity: 0.5;
      }
    }
  }

  .chats-uploads-progress-info {

    .chats-uploads-current-chat-name {
      opacity: 0.8;
      font-size: 0.9rem;
    }
  }
}
