
@mixin full-border-radius ($radius) {
  border-radius: $radius!important;
  -webkit-border-radius: $radius!important;
  -moz-border-radius: $radius!important;
}
@mixin full-border-left-radius ($radius) {
  border-top-left-radius: $radius!important;
  -webkit-border-top-left-radius: $radius!important;
  -moz-border-radius-topleft: $radius!important;
  border-bottom-left-radius: $radius!important;
  -webkit-border-bottom-left-radius: $radius!important;
  -moz-border-radius-bottomleft: $radius!important;
}
@mixin full-border-right-radius ($radius) {
  border-top-right-radius: $radius!important;
  -webkit-border-top-right-radius: $radius!important;
  -moz-border-radius-topright: $radius!important;
  border-bottom-right-radius: $radius!important;
  -webkit-border-bottom-right-radius: $radius!important;
  -moz-border-radius-bottomright: $radius!important;
}
